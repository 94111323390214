<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="900px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer></v-spacer>

            <v-text-field
              autocomplete="off"
              v-model="editedItem.statut_name"
              label="Statut"
              readonly
              @focus="$event.target.select()"
            ></v-text-field>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.ref"
                    label="Reférence"
                    class="pt-3"
                    :readonly="!modify || editedItem.statut_id >= '19'"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-menu
                    v-model="datepicker1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        v-model="editedItem.date_inv"
                        label="Date Inventaire"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="pt-3"
                        :readonly="!modify"
                        :rules="[
                          (v) => !!v || 'Date Inventaire obligatoire',
                          (v) => valideDate || message,
                        ]"
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.date_inv"
                      @input="datepicker1 = false"
                      :max="new Date().toISOString().substr(0, 10)"
                      v-if="modify && editedItem.statut_id < '19'"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-autocomplete
                    :items="agents_list"
                    v-model="editedItem.pilot_id"
                    item-text="nom"
                    item-value="id"
                    :rules="[(v) => !!v || 'Responsable obligatoire']"
                    :label="'Responsable'"
                    :readonly="!modify || editedItem.statut_id >= '19'"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                    :items="[
                      { id: 1, label: 'Un seul comptage' },
                      { id: 2, label: 'Deux comptages' },
                    ]"
                    item-text="label"
                    item-value="id"
                    v-model.number="editedItem.nbcompte"
                    label="Comptage"
                    :rules="[(v) => !!v || 'Nombre de comptage obligatoire']"
                    :readonly="
                      !modify ||
                      editedItem.statut_id != '18' ||
                      item.countproduct > 0
                    "
                    @change="cmpt_id = '1'"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                  <v-checkbox
                    label="partiel"
                    v-model="editedItem.type_inv"
                    :readonly="!modify || editedItem.statut_id != '18'"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    required
                    :readonly="!modify || editedItem.statut_id >= '19'"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fluid>
              <!-- <v-row no-gutters v-if="item.id > 0">
                <v-col cols="12" md="3" class="mt-5">
                  Produits : {{ item.countproduct }}
                </v-col>
                <v-col cols="12" md="3">
                  <vuetify-money
                    v-model="item.tot_stock_phy"
                    label="Quantite Physique"
                    readonly
                    valueWhenIsEmpty="0.00"
                    :backgroundColor="$vuetify.theme.dark ? 'dark' : 'white'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <vuetify-money
                    v-model="item.tot_stock_cmpt"
                    label="Quantite Comptable"
                    readonly
                    valueWhenIsEmpty="0.00"
                    :backgroundColor="$vuetify.theme.dark ? 'dark' : 'white'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <vuetify-money
                    v-model="item.tot_qte_ecart"
                    label="Ecart Quantite"
                    readonly
                    valueWhenIsEmpty="0.00"
                    :backgroundColor="$vuetify.theme.dark ? 'dark' : 'white'"
                  />
                </v-col>

                <v-col cols="12" md="3" class="mt-5"> </v-col>
                <v-col cols="12" md="3">
                  <vuetify-money
                    v-model="item.tot_val_phy"
                    label="Valeur Physique"
                    readonly
                    valueWhenIsEmpty="0.00"
                    :backgroundColor="$vuetify.theme.dark ? 'dark' : 'white'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <vuetify-money
                    v-model="item.tot_val_cmpt"
                    label="Valeur Comptable"
                    readonly
                    valueWhenIsEmpty="0.00"
                    :backgroundColor="$vuetify.theme.dark ? 'dark' : 'white'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <vuetify-money
                    v-model="item.tot_val_ecart"
                    label="Ecart Valeur"
                    readonly
                    valueWhenIsEmpty="0.00"
                    :backgroundColor="$vuetify.theme.dark ? 'dark' : 'white'"
                  />
                </v-col>
              </v-row> -->
              <v-row>
                <v-layout row justify-center align-center v-if="progress">
                  <v-progress-circular
                    indeterminate
                    :size="30"
                    :width="7"
                    color="purple"
                  ></v-progress-circular>
                </v-layout>
                <v-btn
                  color="blue darken-1"
                  @click="isEquipeListClosed = false"
                  text
                  class="ma-2"
                  v-if="item.id > 0"
                >
                  Gestion des Equipes
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn
                  color="blue darken-1"
                  @click="save"
                  text
                  v-if="modify && editedItem.statut_id == '18'"
                >
                  Enregistrer
                </v-btn>
                <v-btn color="blue darken-1" text @click.stop="close">
                  Fermer
                </v-btn>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <equipelist
      :invent="item"
      :showform="!isEquipeListClosed"
      :key="al"
      :agents_list="agents_list"
      @close="closeEquipeList"
    >
    </equipelist>
  </div>
</template>

<script>
const equipelist = () => import("../components/InvEquipeList.vue");
const confirmdialog = () => import("./ConfirmDialog.vue");

import CREATE_INVENT from "../graphql/Inventaire/CREATE_INVENT.gql";
import UPDATE_INVENT from "../graphql/Inventaire/UPDATE_INVENT.gql";
import INSERT_INVENT_DEPOT from "../graphql/Inventaire/INSERT_INVENT_DEPOT.gql";

export default {
  components: {
    confirmdialog,
    equipelist,
  },
  name: "inventform",
  props: {
    item: Object,
    agents_list: Array,
    showForm: Boolean,
    dernier_inv: String,
    cmpt_inv: Number,
  },
  data: () => ({
    tabs2: "",
    progress: false,
    isEquipeListClosed: true,
    valid: true,
    modify: false,
    datepicker1: false,
    datepicker2: false,
    datepicker3: false,

    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      statut_name: "Comptage",
      statut_id: "18",
      nbcompte: 1,
      type_inv: 0,
    },
    editedItem: {},
    ke: 0,
    al: 200,
  }),

  computed: {
    depots() {
      return this.item.depots ? this.item.depots : [];
    },

    formTitle() {
      return this.item.id < 0
        ? "Nouveau Inventaire"
        : "Inventaire " + this.editedItem.code;
    },

    valideDate() {
      let ok = true;
      if (this.item.id < 0) {
        let last = new Date();
        if (this.dernier_inv) last = new Date(this.dernier_inv);
        let inv = new Date(this.editedItem.date_inv);
        ok = this.dernier_inv ? inv > last : inv <= last;
      }
      return ok;
    },
    message() {
      let message = "";
      if (this.dernier_inv) message = "Dernier Inventaire " + this.dernier_inv;
      else message = "Date Invalide ";
      return message;
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    this.defaultItem.nbcompte = this.cmpt_inv;
    if (this.item) {
      if (this.item.id > 0) this.editedItem = Object.assign({}, this.item);
      else this.editedItem = Object.assign({}, this.defaultItem);
    }
    this.modify =
      this.$store.state.auth.includes("02028") || this.$store.state.isadmin;
  },

  methods: {
    closeEquipeList() {
      this.isEquipeListClosed = true;
    },
    close() {
      this.$emit("close", this.editedItem);
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.editedItem.code =
          "INV-" +
          this.editedItem.date_inv.substr(8, 2) +
          "-" +
          this.editedItem.date_inv.substr(5, 2) +
          "-" +
          this.editedItem.date_inv.substr(0, 4);
        if (this.editedItem.type_inv) this.editedItem.type_inv = 1;
        else this.editedItem.type_inv = 0;
        let v;
        if (this.item.id > 0) {
          v = {
            invent: {
              id: this.editedItem.id,
              date_inv: this.editedItem.date_inv,
              comment: this.editedItem.comment,
              pilot_id: this.editedItem.pilot_id,
              statut_id: this.editedItem.statut_id,
              ref: this.editedItem.ref,
              code: this.editedItem.code,
              nbcompte: this.editedItem.nbcompte,
              type_inv: this.editedItem.type_inv,

              write_uid: this.$store.state.me.id,
            },
          };
          this.maj(UPDATE_INVENT, v, true);
        } else {
          v = {
            invent: {
              date_inv: this.editedItem.date_inv,
              comment: this.editedItem.comment,
              pilot_id: this.editedItem.pilot_id,
              statut_id: this.editedItem.statut_id,
              ref: this.editedItem.ref,
              code: this.editedItem.code,
              nbcompte: this.editedItem.nbcompte,
              type_inv: this.editedItem.type_inv,

              create_uid: parseInt(this.$store.state.me.id),
              write_uid: parseInt(this.$store.state.me.id),
            },
          };
          let r = await this.maj(CREATE_INVENT, v, true);
          this.$emit("add");
          if (r) {
            let v = {
              inv_id: r.createInvent.id,
              date_p1: this.$store.state.today,
            };
            this.maj(INSERT_INVENT_DEPOT, v);
          }
        }
      }
    },
  },
};
</script>
